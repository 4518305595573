<template>
  <div>
    <v-dialog
      v-model="monthlyPlanDialog"
      persistent
      max-width="500px"
      transition="slide-y-transition"
    >
      <v-card class="dialog-card-border">
        <v-card-title class="text-h6 font-weight-light">
          {{ $t('Update monthly plan') }}
        </v-card-title>

        <validation-observer
          ref="theForm"
          v-slot="{ handleSubmit, reset }"
        >
          <form
            autocomplete="off"
            @submit.prevent="handleSubmit(submit)"
            @reset.prevent="reset"
          >
            <v-card-text>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                  class="pb-6 px-1 text-subtitle-1"
                >
                  {{ $t('Customer Name') }}
                  {{ customerData.name }}
                </v-col>

                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                  class="warning--text pb-6 px-1 text-subtitle-1"
                >
                  {{ $t('Customer Balance') }}
                  {{ $_format_number(customerData.balance) }}
                </v-col>

                <v-col
                  class="px-1 pb-8 text-subtitle-1"
                  cols="12"
                >
                  {{ $t('Monthly Receipt Amount') }}
                  {{ $_format_number(monthlyReceiptAmount) }}
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('No. of months')"
                    rules="required"
                  >
                    <v-select
                      v-model="formData.no_of_months"
                      :label="$t('No. of months')"
                      :items="monthPlanList"
                      outlined
                      dense
                      clearable
                      :color="$_input_color()"
                      :menu-props="{'offset-y': true}"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Excluded Month')"
                  >
                    <v-select
                      v-model="formData.excluded_month"
                      :label="$t('Excluded Month')"
                      :items="monthsList"
                      outlined
                      dense
                      clearable
                      :color="$_input_color()"
                      :menu-props="{'offset-y': true}"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Month Frequency')"
                    rules="required"
                  >
                    <v-select
                      v-model="formData.month_frequency"
                      :label="$t('Month Frequency')"
                      :items="monthsList"
                      outlined
                      dense
                      clearable
                      :color="$_input_color()"
                      :menu-props="{'offset-y': true}"
                      :error-messages="errors"
                    >
                      <template #item="{item}">
                        {{ item.text }} {{ $t('month a time') }}
                      </template>
                      <template #selection="{item}">
                        {{ item.text }} {{ $t('month a time') }}
                      </template>
                    </v-select>
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Next Receipt Date')"
                    rules="required"
                  >
                    <app-date-picker
                      v-model="formData.next_receipt_date"
                      :label="$t('Next Receipt Date')"
                      no-title
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider />

            <v-card-actions class="py-3">
              <app-form-tip />

              <v-spacer />

              <app-submit-button :edit-mode="true" />
            </v-card-actions>
          </form>
        </validation-observer>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  props: {
    monthlyPlanDialog: { type: Boolean, default: false },
    formData: { type: Object, default: () => { } },
    customerData: { type: Object, default: () => { } },
  },

  computed: {
    ...mapGetters([
      'monthsList',
      'monthPlanList',
    ]),

    monthlyReceiptAmount() {
      let customerBalance = Math.abs(this.customerData.balance ?? 0)
      return customerBalance / this.formData.no_of_months
    }
  },

  methods: {
    closeDialog() {
      this.$refs.theForm.reset()
      this.$emit('close')
    },

    submit() {
      this.$_confirm({ type: 'approve' }).then(confirmed => {
        if (confirmed) {
          axios.put(`monthly-plan/${this.formData.id}/`, this.formData).then(() => {
            this.$_notify('Updated successfully')
            this.closeDialog()
          })
        }
      })
    }
  },
}
</script>
<style lang="scss">
</style>